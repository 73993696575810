export const conversionTable = [
    { dpmo: 933193, score: 0 },
    { dpmo: 915434, score: 2 },
    { dpmo: 894350, score: 4 },
    { dpmo: 869705, score: 5 },
    { dpmo: 841345, score: 7 },
    { dpmo: 809213, score: 9 },
    { dpmo: 773373, score: 11 },
    { dpmo: 734014, score: 13 },
    { dpmo: 691462, score: 15 },
    { dpmo: 646170, score: 16 },
    { dpmo: 598706, score: 18 },
    { dpmo: 549738, score: 20 },
    { dpmo: 500000, score: 22 },
    { dpmo: 450262, score: 24 },
    { dpmo: 401294, score: 25 },
    { dpmo: 353830, score: 27 },
    { dpmo: 308538, score: 29 },
    { dpmo: 265986, score: 31 },
    { dpmo: 226627, score: 33 },
    { dpmo: 190787, score: 35 },
    { dpmo: 158655, score: 36 },
    { dpmo: 130295, score: 38 },
    { dpmo: 105650, score: 40 },
    { dpmo: 84566, score: 42 },
    { dpmo: 66807, score: 44 },
    { dpmo: 52081, score: 45 },
    { dpmo: 40059, score: 47 },
    { dpmo: 30396, score: 49 },
    { dpmo: 22750, score: 51 },
    { dpmo: 16793, score: 53 },
    { dpmo: 12224, score: 55 },
    { dpmo: 8774, score: 56 },
    { dpmo: 6210, score: 58 },
    { dpmo: 4332, score: 60 },
    { dpmo: 2980, score: 62 },
    { dpmo: 2020, score: 64 },
    { dpmo: 1350, score: 65 },
    { dpmo: 889, score: 67 },
    { dpmo: 577, score: 69 },
    { dpmo: 369, score: 71 },
    { dpmo: 233, score: 73 },
    { dpmo: 144, score: 75 },
    { dpmo: 88, score: 76 },
    { dpmo: 53, score: 78 },
    { dpmo: 32, score: 80 },
    { dpmo: 19, score: 82 },
    { dpmo: 11, score: 84 },
    { dpmo: 6, score: 85 },
    { dpmo: 3, score: 87 },
    { dpmo: 2, score: 89 },
    { dpmo: 1, score: 91 },
    { dpmo: 0, score: 93 },
    { dpmo: 0, score: 95 },
    { dpmo: 0, score: 96 },
    { dpmo: 0, score: 98 },
    { dpmo: 0, score: 100 },
];
