import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  SvgIcon,
  chipClasses,
} from "@mui/material";
import {
  ArrowOutward,
  InfoOutlined,
} from "@mui/icons-material";
import { Tooltip as MuiTooltip } from "@mui/material";

// import XTwitter from "../../assets/images/x_twitter.png";
// import LinkedIn from "../../assets/images/linkedin.png";
// import BankOfAmerica from "../../assets/images/bank_of_america.png";
// import BestBuy from "../../assets/images/best_buy.png";
// import Microsoft from "../../assets/images/microsoft.png";
// import Bill from "../../assets/images/bill.png";
import { BackgroundCircle } from "../common/BackgroundCircle";
import { CircularProgressScore } from "../common/CircularProgressScore";
import { ReactComponent as FinancialTool } from "../../assets/svg/financial_tool.svg";
import { ReactComponent as ForecastedExpenses } from "../../assets/svg/forecasted_expenses.svg";
import { ReactComponent as CashOnDeposit } from "../../assets/svg/cash_on_deposit.svg";
import { ReactComponent as CashReserve } from "../../assets/svg/cash_reserve.svg";
//import { ReactComponent as LiaFi } from "../../assets/svg/lia_fi.svg";
// import {
//   CartesianGrid,
//   Dot,
//   DotProps,
//   Legend,
//   Line,
//   LineChart,
//   ReferenceArea,
//   ReferenceLine,
//   ResponsiveContainer,
//   Tooltip,
//   XAxis,
//   YAxis,
// } from "recharts";
import {useSelector } from "react-redux";
import { TRootState } from "../../store";
import { InitialState } from "../../store/slices/authSlice";
import useAccountDetails from "../../hooks/useAccountDetails";
import { getTorScoreLabel } from "../../utils/calculations";

// import { useEffect, useState } from "react";
// import NarmiAPI from "../../store/modules/NarmiAPI";
// import MXAPI from "../../store/modules/MXAPI";
// import { formatMoney } from "../../utils/formatMoney";
// import { calculatePercentageChange } from "../../utils/countPercentages";
// import React from "react";
// import { IMxTransaction } from "../../store/modules/MXAPI/models";
// import {
//   ITransaction,
//   IUserTransactionsResponse,
// } from "../../store/modules/NarmiAPI/models";
// import { TransactionType } from "../../utils/transactionType";
// import { calculateTotalIncomeExpense } from "../../utils/calculateTotalIncomeExpense";
// import { getTorScoreLabel } from "../../utils/getTorScoreLabel";
// import { IPageProps } from "../../utils/IPageProps";
// import { getNarmiTransactionsByType } from "../../utils/getNarmiTransactionsByType";
// import { getMXTransactionsByType } from "../../utils/getMXTransactionsByType";
// import { sumBalances } from "../../utils/countBalances";
// import { IPageProps } from "../../utils/IPageProps";
// import { calculateControlLimits } from "../../utils/calculateControlLimits";

// Commented out for MVP
//const endOfMonthChart = [
//   {
//     name: "1",
//     e: 300,
//     color: "#B7D8FD",
//   },
//   {
//     name: "2",
//     e: 400,
//     color: "#B7D8FD",
//   },
//   {
//     name: "3",
//     e: 200,
//     color: "#B7D8FD",
//   },
//   {
//     name: "4",
//     e: 100,
//     color: "#B7D8FD",
//   },
//   {
//     name: "5",
//     e: 450,
//     color: "#F0F7FF",
//   },
//   {
//     name: "6",
//     e: 500,
//     color: "#F0F7FF",
//   },
//   {
//     name: "7",
//     e: 300,
//     color: "#F0F7FF",
//   },
// ];

// const BarWithBorder = (borderRadius: number) => {
//   return (props: any) => {
//     const { fill, x, y, width, height } = props;
//     return (
//       <g>
//         <rect
//           x={x}
//           y={y}
//           width={width}
//           height={height}
//           stroke="none"
//           fill={fill}
//           rx={borderRadius}
//           ry={borderRadius}
//         />
//         <rect x={x} y={y} width={width} stroke="none" />
//       </g>
//     );
//   };
// };

// interface IChartData {
//   day: number;
//   DailyNet: number;
// }

// interface IChartTransaction {
//   amount: number;
//   date: string;
//   type: TransactionType;
// }

export const Dashboard = () => {
  //const dispatch = useDispatch();
  const accountDetails = useAccountDetails();
  const isLoadingAccountDetails = useSelector(
    (state: TRootState) => (state.auth as InitialState).isLoadingAccountDetails
  );
  const interestRate = useSelector(
    (state: TRootState) => (state.auth as InitialState).interestRate
  );
  // const narmiApiKey = useSelector(
  //   (state: TRootState) => (state.auth as InitialState).narmiAuth.narmiApiKey
  // );
  // const narmiApiSecret = useSelector(
  //   (state: TRootState) => (state.auth as InitialState).narmiAuth.narmiApiSecret
  // );
  // const mxSessionToken = useSelector(
  //   (state: TRootState) => (state.auth as InitialState).mxAuth.mdSessionToken
  // );
  // const [selectedRange, setSelectedRange] = useState<"30days" | "7days">(
  //   "30days"
  // );
  // const [isLoading, setIsLoading] = useState(true);
  // const [income, setIncome] = useState("0.00");
  // const [incomePercentage, setIncomePercentage] = useState(0);
  // const [expenses, setExpenses] = useState("0.00");
  // const [expensesPercentage, setExpensesPercentage] = useState(0);
  // const [chartData, setChartData] = useState<IChartData[]>([]);
  // const [LCL, setLCL] = useState(0);
  // const [UCL, setUCL] = useState(0);
  // const [getNarmiUserAccounts] = NarmiAPI().useLazyGetUserAccountsQuery();
  // const [getNarmiUserTransactions] =
  //   NarmiAPI().useLazyGetUserTransactionsQuery();
  // const [getMXUserAccounts] = MXAPI().useLazyGetMXUserAccountsQuery();
  // const [getMXUserTransactions] =
  //   MXAPI().useLazyGetMXUserTransactionsByAccountQuery();


  // const CustomDot: React.FC<DotProps & { value: number }> = (props) => {
  //   const { cx, cy, value } = props;
  //   if (value > UCL || value < LCL) {
  //     return (
  //       <circle cx={cx} cy={cy} r={5} fill={value > UCL ? "gray" : "gray"} />
  //     );
  //   }
  //   return <Dot {...props} />;
  // };

  // useEffect(() => {
  //   const getIncomeAndExpenses = async () => {
  //     try {
  //       setIsLoading(true);

  //       const narmiAccountData = await getNarmiUserAccounts({
  //         api_key: narmiApiKey,
  //         apiSecret: narmiApiSecret,
  //       }).unwrap();

  //       const mxAccountData = await getMXUserAccounts({
  //         md_session_token: mxSessionToken,
  //       }).unwrap();

  //       const externalNarmiAccounts = narmiAccountData.accounts.filter(
  //         (account) => account.source === "external"
  //       );
  //       const checkMicrList = externalNarmiAccounts.map(
  //         (account) => account.check_micr
  //       );
  //       const matchingMxAccounts = mxAccountData.accounts.filter((mxAccount) =>
  //         checkMicrList.includes(mxAccount.account_number)
  //       );

  //       let date = new Date();
  //       let prevousDate = new Date();
  //       let chart: IChartData[] = [];
  //       let limitsDate = new Date();

        // limitsDate.setDate(limitsDate.getDate() - 90)

  //       switch (selectedRange) {
  //         case "30days":
  //           chart = initializeChartWithData(date, prevousDate, chart, 30);
  //           break;
  //         case "7days":
  //           chart = initializeChartWithData(date, prevousDate, chart, 7);
  //           break;
  //         default:
  //           break;
  //       }

  //       const fromDate = date.toISOString().split("T")[0];
  //       const frompreviousDate = prevousDate.toISOString().split("T")[0];
  //       const toDate = new Date().toISOString().split("T")[0];
  //       const fromDateLimits = limitsDate.toISOString().split("T")[0];

  //       const narmiTransactionsLimitsData = await getNarmiUserTransactions({
  //         api_key: narmiApiKey,
  //         apiSecret: narmiApiSecret,
  //         search: `settled>${fromDateLimits}`,
  //         records_per_page: 1000,
  //       }).unwrap();

  //       const narmiTransactionsData = await getNarmiUserTransactions({
  //         api_key: narmiApiKey,
  //         apiSecret: narmiApiSecret,
  //         search: `settled>${fromDate}`,
  //         records_per_page: 1000,
  //       }).unwrap();

  //       const narmiPreviousTransactionsData = await getNarmiUserTransactions({
  //         api_key: narmiApiKey,
  //         apiSecret: narmiApiSecret,
  //         search: `settled>${frompreviousDate}`,
  //         records_per_page: 1000,
  //       }).unwrap();

  //       const mxTransactionsLimitsData = await Promise.all(
  //         matchingMxAccounts.map(async (account) => {
  //           const transactions = await getMXUserTransactions({
  //             id: account.guid,
  //             md_session_token: mxSessionToken,
  //             from_date: fromDateLimits,
  //             to_date: toDate,
  //             records_per_page: 1000,
  //           }).unwrap();
  //           return transactions.transactions;
  //         })
  //       );

  //       const mxTransactionsData = await Promise.all(
  //         matchingMxAccounts.map(async (account) => {
  //           const transactions = await getMXUserTransactions({
  //             id: account.guid,
  //             md_session_token: mxSessionToken,
  //             from_date: fromDate,
  //             to_date: toDate,
  //             records_per_page: 1000,
  //           }).unwrap();
  //           return transactions.transactions;
  //         })
  //       );

  //       const mxPreviousTransactionsData = await Promise.all(
  //         matchingMxAccounts.map(async (account) => {
  //           const transactions = await getMXUserTransactions({
  //             id: account.guid,
  //             md_session_token: mxSessionToken,
  //             from_date: frompreviousDate,
  //             to_date: fromDate,
  //             records_per_page: 1000,
  //           }).unwrap();
  //           return transactions.transactions;
  //         })
  //       );

  //       const mxTransactionsLimitsDataAll = mxTransactionsLimitsData.reduce(
  //         (acc, curr) => acc.concat(curr),
  //         []
  //       );


  //       const mxTransactionsDataAll = mxTransactionsData.reduce(
  //         (acc, curr) => acc.concat(curr),
  //         []
  //       );

  //       const mxPreviousTransactionsDataAll = mxPreviousTransactionsData.reduce(
  //         (acc, curr) => acc.concat(curr),
  //         []
  //       );

  //       //Calculates the total income and expenses
  //       const totalIncome = calculateTotalIncomeExpense(
  //         narmiTransactionsData.transactions,
  //         mxTransactionsDataAll,
  //         TransactionType.Credit
  //       );
  //       setIncome(formatMoney(totalIncome));

  //       const totalExpenses = calculateTotalIncomeExpense(
  //         narmiTransactionsData.transactions,
  //         mxTransactionsDataAll,
  //         TransactionType.Debit
  //       );
  //       setExpenses(formatMoney(totalExpenses));

  //       const previousTotalIncome = calculateTotalIncomeExpense(
  //         narmiPreviousTransactionsData.transactions,
  //         mxPreviousTransactionsDataAll,
  //         TransactionType.Credit
  //       );
  //       setIncomePercentage(
  //         calculatePercentageChange(totalIncome, previousTotalIncome)
  //       );

  //       const previousTotalExpenses = calculateTotalIncomeExpense(
  //         narmiPreviousTransactionsData.transactions,
  //         mxPreviousTransactionsDataAll,
  //         TransactionType.Debit
  //       );
  //       setExpensesPercentage(
  //         calculatePercentageChange(totalExpenses, previousTotalExpenses)
  //       );

  //       processTransactions(
  //         narmiTransactionsData,
  //         mxTransactionsDataAll,
  //         narmiTransactionsLimitsData,
  //         mxTransactionsLimitsDataAll,
  //         chart
  //       );

  //       setChartData(chart);
  //     } catch (error) {
  //       console.error("Error", error);
  //       dispatch(setIsAuthorized(false));
  //     } finally {
  //       setIsLoading(false);
  //     }

  //     function processTransactions(
  //       narmiTransactionsData: IUserTransactionsResponse,
  //       mxTransactionsDataAll: IMxTransaction[],
  //       narmiTransactionsDataLimits: IUserTransactionsResponse,
  //       mxTransactionsDataLimits: IMxTransaction[],
  //       chart: IChartData[]
  //     ) {
  //       const narmiDebitTransactionsChartData = getNarmiTransactionsByTypeChart(
  //         narmiTransactionsData.transactions,
  //         TransactionType.Debit
  //       );
  //       const mxDebitTransactionsChartData = getMXTransactionsByTypeChart(
  //         mxTransactionsDataAll,
  //         TransactionType.Debit
  //       );
  //       const narmiCreditTransactionsChartData =
  //         getNarmiTransactionsByTypeChart(
  //           narmiTransactionsData.transactions,
  //           TransactionType.Credit
  //         );
  //       const mxCreditTransactionsChartData = getMXTransactionsByTypeChart(
  //         mxTransactionsDataAll,
  //         TransactionType.Credit
  //       );

        // const transactions = [
        //   ...narmiDebitTransactionsChartData,
        //   ...mxDebitTransactionsChartData,
        //   ...narmiCreditTransactionsChartData,
        //   ...mxCreditTransactionsChartData,
        // ];

  //       updateChartWithTransactions(transactions, chart, selectedRange);

      //   const controlLimits = calculateControlLimits(narmiTransactionsDataLimits.transactions, mxTransactionsDataLimits);

      //   setUCL(controlLimits.UCL);
      //   setLCL(controlLimits.LCL);
      // }

  //     function initializeChartWithData(
  //       date: Date,
  //       prevousDate: Date,
  //       chart: IChartData[],
  //       range: number
  //     ) {
  //       date.setDate(date.getDate() - range);
  //       prevousDate.setDate(prevousDate.getDate() - range * 2);
  //       chart = Array.from({ length: range }, (_, index) => ({
  //         day: index + 1,
  //         DailyNet: 0,
  //       }));
  //       return chart;
  //     }

  //     function getNarmiTransactionsByTypeChart(
  //       transactions: ITransaction[],
  //       type: number
  //     ) {
  //       switch (type) {
  //         case TransactionType.Credit:
  //           return transactions
  //             .filter((transaction) => transaction.amount > 0)
  //             .map((transaction) => ({
  //               amount: parseFloat((transaction.amount / 100).toFixed(2)),
  //               date: transaction.settled_at,
  //               type: TransactionType.Credit,
  //             }));
  //         case TransactionType.Debit:
  //           return transactions
  //             .filter((transaction) => transaction.amount < 0)
  //             .map((transaction) => ({
  //               amount: parseFloat((transaction.amount / 100).toFixed(2)),
  //               date: transaction.settled_at,
  //               type: TransactionType.Debit,
  //             }));
  //         default:
  //           return [];
  //       }
  //     }

  //     function getMXTransactionsByTypeChart(
  //       transactions: IMxTransaction[],
  //       type: number
  //     ) {
  //       return transactions
  //         .filter((transaction) => transaction.transaction_type === type)
  //         .map((transaction) => ({
  //           amount: transaction.amount,
  //           date: transaction.date,
  //           type: transaction.transaction_type,
  //         }));
  //     }

  //     function updateChartWithTransactions(
  //       transactions: IChartTransaction[],
  //       chart: IChartData[],
  //       selectedRange: "30days" | "7days"
  //     ): void {
  //       // Initialize a map to keep track of transactions by day and type
  //       const transactionsByDay: {
  //         [day: number]: { type1: number; type2: number };
  //       } = {};

  //       transactions.forEach((transaction) => {
  //         const date = new Date(transaction.date);
  //         const day = date.getDate();

  //         if (day >= 1 && day <= (selectedRange === "30days" ? 30 : 7)) {
  //           if (!transactionsByDay[day]) {
  //             transactionsByDay[day] = { type1: 0, type2: 0 };
  //           }

  //           if (transaction.type === TransactionType.Debit) {
  //             transactionsByDay[day].type1 += transaction.amount;
  //           } else if (transaction.type === TransactionType.Credit) {
  //             transactionsByDay[day].type2 += transaction.amount;
  //           }
  //         } else {
  //           console.warn(
  //             `Transaction date ${transaction.date} is out of range for the current month`
  //           );
  //         }
  //       });

  //       // Update the chart data with the difference between type2 and type1 transactions
  //       for (let day = 1; day <= (selectedRange === "30days" ? 30 : 7); day++) {
  //         const transactionData = transactionsByDay[day] || {
  //           type1: 0,
  //           type2: 0,
  //         };
  //         const difference = (
  //           transactionData.type2 - transactionData.type1
  //         ).toFixed(2);
  //         chart[day - 1].DailyNet = parseFloat(difference);
  //       }
  //     }


  //   };
  //   getIncomeAndExpenses();
  // }, [
  //   narmiApiKey,
  //   narmiApiSecret,
  //   mxSessionToken,
  //   getNarmiUserAccounts,
  //   getMXUserAccounts,
  //   dispatch,
  //   getNarmiUserTransactions,
  //   getMXUserTransactions,
  //   selectedRange,
  // ]);

  return (
    <>
      <Box
        sx={{
          minHeight: "50vh",
        }}
        marginBottom="50px"
      >
        <Box
          zIndex={-1}
          position="absolute"
          right="0px"
          sx={{ minHeight: "50vh" }}
          display={{ xs: "none", sm: "flex" }}
          justifyContent="flex-end"
          alignItems="center"
          overflow="hidden"
        >
          <BackgroundCircle size={800}>
            <BackgroundCircle size={550}>
              <BackgroundCircle size={300}></BackgroundCircle>
            </BackgroundCircle>
          </BackgroundCircle>
        </Box>
        <Box marginTop={{ xs: 8, sm: 0 }}>
          <Grid
            container
            spacing={10}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={3}>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <Box
                  sx={{
                    textAlign: "center",
                    color: "#192653",
                    fontFamily: "Work Sans",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "27px",
                    letterSpacing: "-0.48px",
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  Tor Score
                  <MuiTooltip
                    title={
                      <span style={{fontSize: '14px'}}>
                        Tor Score is a cutting-edge tool designed to
                        revolutionize the way you monitor your business
                        finances. Want to learn more?{" "}
                        <button
                          onClick={() => { window.top ? window.top.location.href ="https://liafi.co/tor-score/" : window.location.href ="https://liafi.co/tor-score/"}}
                          style={{
                            background: "none",
                            border: "none",
                            padding: "0",
                            margin: "0",
                            color: "inherit",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Click here
                        </button>
                      </span>
                    }
                    arrow
                  >
                    <InfoOutlined sx={{ color: "#192653" }} />
                  </MuiTooltip>
                </Box>
                <Box>
                  {isLoadingAccountDetails ? (
                    <CircularProgress style={{ marginTop: "10px" }} />
                  ) : (
                    <CircularProgressScore
                      value={
                        accountDetails.cashflowScore < 0
                          ? 0
                          : accountDetails.cashflowScore
                      }
                      size={300}
                      display={{ xs: "inline-flex", sm: "inline-flex" }}
                      chip={
                        <Chip
                          label={getTorScoreLabel(
                            accountDetails.cashflowScore < 0
                              ? 0
                              : accountDetails.cashflowScore
                          )}
                          size="medium"
                          sx={{
                            bgcolor: "#36C685",
                            color: "#ECFCF4",
                            leadingTrim: "both",
                            textEdge: "cap",
                            fontFamily: "Work Sans",
                            fontSize: "18px",
                            fontWeight: "600",
                            lineHeight: "24px",
                            letterSpacing: "-0.18px",
                            height: { xs: "30px", sm: "initial" },
                            width: { xs: "fit-content", sm: "fit-content" },
                            [`& .${chipClasses.label}`]: {
                              overflow: "initial",
                            },
                          }}
                        />
                      }
                    />
                  )}
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Stack direction="row" spacing={2} justifyContent="space-evenly">
                <Grid
                  container
                  spacing={2}
                  sx={{
                    flexWrap: { xs: "nowrap", sm: "wrap" },
                    overflowX: "scroll",
                    paddingX: { xs: 3, sm: 0 },
                    paddingBottom: 1,
                    "::-webkit-scrollbar": {
                      display: "none",
                    },
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ minWidth: { xs: "85vw", sm: "initial" } }}
                  >
                    <Card sx={{ borderRadius: "20px" }}>
                      <Box padding="40px 40px">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <SvgIcon
                            component={FinancialTool}
                            viewBox="-6 -6 48 48"
                            sx={{
                              bgcolor: "#FEF337",
                              borderRadius: "100px",
                              width: "80px",
                              height: "80px",
                            }}
                          />
                          <ArrowOutward sx={{ color: "#1881FA" }} />
                        </Stack>
                        <Box
                          sx={{
                            color: "#192653",
                            fontFamily: "Work Sans",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "27px",
                            letterSpacing: "-0.4px",
                          }}
                          padding="10px 0px"
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          Cash on Hand{" "}
                          <MuiTooltip
                            title={
                              <span style={{fontSize: '14px'}}>
                                Aggregated amount from all accounts linked in LiaFi. There could be a 24-hour delay based on your recent transaction history.
                              </span>
                            }
                            arrow
                          >
                            <InfoOutlined
                              sx={{ margin: "0px 10px", color: "#B8BCCA" }}
                            />
                          </MuiTooltip>
                        </Box>
                        <Box
                          component={"span"}
                          sx={{
                            color: "#192653",
                            leadingTrim: "both",
                            textEdge: "cap",
                            fontFamily: "Work Sans",
                            fontSize: { xs: "36px", sm: "48px" },
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "56px",
                            letterSpacing: "-0.96px",
                          }}
                        >
                          {isLoadingAccountDetails ? (
                            <CircularProgress style={{ marginTop: "10px" }} />
                          ) : (
                            <>
                              <Box
                                component={"span"}
                                sx={{
                                  color: "#B8BCCA",
                                  marginRight: "4px",
                                }}
                              >
                                $
                              </Box>
                              {accountDetails.cashOnHand}
                            </>
                          )}
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ minWidth: { xs: "85vw", sm: "initial" } }}
                  >
                    <Card sx={{ borderRadius: "20px" }}>
                      <Box padding="40px 40px">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <SvgIcon
                            component={ForecastedExpenses}
                            viewBox="18 18 48 48"
                            sx={{
                              bgcolor: "#FEF337",
                              borderRadius: "100px",
                              width: "80px",
                              height: "80px",
                            }}
                          />
                          <ArrowOutward sx={{ color: "#1881FA" }} />
                        </Stack>
                        <Box
                          sx={{
                            color: "#192653",
                            fontFamily: "Work Sans",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "27px",
                            letterSpacing: "-0.4px",
                          }}
                          padding="10px 0px"
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          Forecasted Expenses{" "}
                          <MuiTooltip
                            title={
                              <span style={{fontSize: '14px'}}>
                                The forecast is based on transaction history and represents the expenses predicted for the upcoming month. To get an accurate forecast, please connect all your operational accounts{" "}
                                <button
                                  onClick={() => { window.top ? window.top.location.href ="https://magnolia-liafi.uat.narmitech.com/settings/linked_accounts" : window.location.href ="https://magnolia-liafi.uat.narmitech.com/settings/linked_accounts"}}
                                  style={{
                                    background: "none",
                                    border: "none",
                                    padding: "0",
                                    margin: "0",
                                    color: "inherit",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                >
                                  here
                                </button>
                              </span>
                            }
                            arrow
                          >
                            <InfoOutlined
                              sx={{ margin: "0px 10px", color: "#B8BCCA" }}
                            />
                          </MuiTooltip>
                        </Box>
                        <Box
                          component={"span"}
                          sx={{
                            color: "#192653",
                            leadingTrim: "both",
                            textEdge: "cap",
                            fontFamily: "Work Sans",
                            fontSize: { xs: "36px", sm: "48px" },
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "56px",
                            letterSpacing: "-0.96px",
                          }}
                        >
                          {isLoadingAccountDetails ? (
                            <CircularProgress style={{ marginTop: "10px" }} />
                          ) : (
                            <>
                              <Box
                                component={"span"}
                                sx={{
                                  color: "#B8BCCA",
                                  marginRight: "4px",
                                }}
                              >
                                $
                              </Box>
                              {accountDetails.forecastedExpenses}
                            </>
                          )}
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ minWidth: { xs: "85vw", sm: "initial" } }}
                  >
                    <Card sx={{ borderRadius: "20px" }}>
                      <Box padding="40px 40px">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <SvgIcon
                            component={CashOnDeposit}
                            viewBox="-6 -6 48 48"
                            sx={{
                              bgcolor: "#FEF337",
                              borderRadius: "100px",
                              width: "80px",
                              height: "80px",
                            }}
                          />
                          <Button
                            variant="contained"
                            sx={{
                              fontFamily: "Mulish",
                              textTransform: "none",
                              fontSize: "16px",
                              lineHeight: "12px",
                              letterSpacing: "0.32px",
                              padding: { xs: "15px 20px", sm: "18px 24px" },
                              backgroundColor: "#1881FA",
                              fontWeight: "700",
                              ":hover": {
                                backgroundColor: "#1881FA",
                              },
                            }}
                            onClick={() => {
                              window.top
                                ? (window.top.location.href =
                                    "https://magnolia-liafi.uat.narmitech.com/transfer/funds")
                                : (window.location.href =
                                    "https://magnolia-liafi.uat.narmitech.com/transfer/funds");
                            }}
                          >
                            Get it back
                          </Button>
                        </Stack>
                        <Box
                          sx={{
                            color: "#192653",
                            fontFamily: "Work Sans",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "27px",
                            letterSpacing: "-0.4px",
                          }}
                          padding="10px 0px"
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          Cash on Deposit in Liafi{" "}
                          <MuiTooltip
                            title={
                              <span style={{fontSize: '14px'}}>
                               Cash available in your LiaFi account.
                              </span>
                            }
                            arrow
                          >
                            <InfoOutlined
                              sx={{ margin: "0px 10px", color: "#B8BCCA" }}
                            />
                          </MuiTooltip>
                        </Box>
                        <Box
                          component={"span"}
                          sx={{
                            color: "#192653",
                            leadingTrim: "both",
                            textEdge: "cap",
                            fontFamily: "Work Sans",
                            fontSize: { xs: "36px", sm: "48px" },
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "56px",
                            letterSpacing: "-0.96px",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          {isLoadingAccountDetails ? (
                            <CircularProgress style={{ marginTop: "10px" }} />
                          ) : (
                            <>
                              <Box
                                component={"span"}
                                sx={{
                                  color: "#B8BCCA",
                                  marginRight: "4px",
                                }}
                              >
                                $
                              </Box>
                              {accountDetails.cashOnDeposit}
                            </>
                          )}
                          <Box
                            component={"span"}
                            sx={{
                              color: "#36C685",
                              marginLeft: "15px",
                              fontSize: "30px",
                            }}
                          >
                            {interestRate ? interestRate.toFixed(2) : "0"}%
                          </Box>
                          <MuiTooltip
                            title={
                              <span style={{fontSize: '14px'}}>
                               The percentage rate reflecting the total amount of interest paid on an account based on the interest rate and the frequency of compounding for a one-year period.
                              </span>
                            }
                            arrow
                          >
                            <InfoOutlined
                              sx={{ margin: "0px 5px", color: "#B8BCCA" }}
                            />
                          </MuiTooltip>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ minWidth: { xs: "85vw", sm: "initial" } }}
                  >
                    <Card sx={{ borderRadius: "20px" }}>
                      <Box padding="40px 40px">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <SvgIcon
                            component={CashReserve}
                            viewBox="-6 -6 48 48"
                            sx={{
                              bgcolor: "#FEF337",
                              borderRadius: "100px",
                              width: "80px",
                              height: "80px",
                            }}
                          />
                          <ArrowOutward sx={{ color: "#1881FA" }} />
                        </Stack>
                        <Box
                          sx={{
                            color: "#192653",
                            fontFamily: "Work Sans",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "27px",
                            letterSpacing: "-0.4px",
                          }}
                          padding="10px 0px"
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          Cash Reserve{" "}
                          <MuiTooltip
                            title={
                              <span style={{fontSize: '14px'}}>
                               This provides an estimate of how many months your company can operate with the current cash reserve.
                              </span>
                            }
                            arrow
                          >
                            <InfoOutlined
                              sx={{ margin: "0px 10px", color: "#B8BCCA" }}
                            />
                          </MuiTooltip>
                        </Box>
                        {isLoadingAccountDetails ? (
                          <CircularProgress style={{ marginTop: "10px" }} />
                        ) : (
                          <>
                            <Box
                              component={"span"}
                              sx={{
                                color: "#192653",
                                leadingTrim: "both",
                                textEdge: "cap",
                                fontFamily: "Work Sans",
                                fontSize: { xs: "36px", sm: "48px" },
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "56px",
                                letterSpacing: "-0.96px",
                              }}
                            >
                              {accountDetails.cashReserve}
                              <Box
                                component={"span"}
                                sx={{
                                  color: "#B8BCCA",
                                  marginRight: "4px",
                                }}
                              >
                                months
                              </Box>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* <Box
        sx={{
          borderRadius: "40px 40px 0px 0px",
          backgroundColor: "#FFFFFF",
        }}
        padding={{ xs: "20px", sm: "50px" }}
      > */}
        {/* Commented out for MVP
         <Box
          sx={{
            color: "#192653",
            leadingTrim: "both",
            textEdge: "cap",
            fontFamily: "Mulish",
            fontSize: "26.509px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "28.013px",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <SvgIcon
            component={LiaFi}
            viewBox="-6 -14 56 56"
            sx={{
              width: "80px",
              height: "80px",
            }}
          />
          Cashflow Insights
          <InfoOutlined sx={{ color: "#192653", margin: "8px" }} />
        </Box> */}
        {/* Commented out for MVP 
        <Grid
          container
          spacing={4}
          alignItems="stretch"
          sx={{
            flexWrap: { xs: "nowrap", sm: "wrap" },
            overflowX: "scroll",
            paddingX: { xs: 3, sm: 0 },
            paddingBottom: 2,
            "::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              display: "flex",
              minWidth: { xs: "90vw", sm: "initial" },
            }}
          >
            <Card
              sx={{
                borderRadius: "20px",
              }}
            >
              <Box
                padding="40px 40px"
                height="100%"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      fontFamily: "Work Sans",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "27px",
                      letterSpacing: "-0.4px",
                    }}
                  >
                    End of the month expenses
                  </Box>
                </Stack>
                <Box
                  sx={{
                    color: "#192653",
                    fontFamily: "Work Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "21px",
                    letterSpacing: "-0.14px",
                  }}
                  padding="10px 0px"
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  Consider distributing your payments throughout the month, to
                  avoid an overwhelming surge at month's end
                </Box>
                <Box width="100%" height={200}>
                  <ResponsiveContainer>
                    <BarChart data={endOfMonthChart} barCategoryGap={3}>
                      <Bar dataKey="e" shape={BarWithBorder(9.35)}>
                        {endOfMonthChart.map((entry, index) => (
                          <Cell
                            cursor="pointer"
                            fill={entry.color}
                            key={`cell-${index}`}
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ display: "flex", minWidth: { xs: "90vw", sm: "initial" } }}
          >
            <Card sx={{ borderRadius: "20px" }}>
              <Box
                padding="40px 40px"
                height="100%"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      fontFamily: "Work Sans",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "27px",
                      letterSpacing: "-0.4px",
                    }}
                  >
                    Irregular Invoicing
                  </Box>
                </Stack>
                <Box
                  sx={{
                    color: "#192653",
                    fontFamily: "Work Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "21px",
                    letterSpacing: "-0.14px",
                  }}
                  padding="10px 0px"
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  For businesses, ensure that you are billing your customers
                  promptly and accurately.
                </Box>
                <Box width="100%" height={200}>
                  <ResponsiveContainer>
                    <ComposedChart
                      data={[
                        {
                          name: "Page A",
                          uv: 590,
                          pv: 800,
                          amt: 1400,
                          cnt: 490,
                        },
                        {
                          name: "Page B",
                          uv: 868,
                          pv: 967,
                          amt: 1506,
                          cnt: 590,
                        },
                        {
                          name: "Page C",
                          uv: 1397,
                          pv: 1098,
                          amt: 989,
                          cnt: 350,
                        },
                        {
                          name: "Page D",
                          uv: 1480,
                          pv: 1200,
                          amt: 1228,
                          cnt: 480,
                        },
                        {
                          name: "Page E",
                          uv: 1520,
                          pv: 1108,
                          amt: 1100,
                          cnt: 460,
                        },
                        {
                          name: "Page F",
                          uv: 1400,
                          pv: 680,
                          amt: 1700,
                          cnt: 380,
                        },
                      ]}
                    >
                      <Line type="monotone" dataKey="uv" stroke="#DCECFE" />
                      <Area dataKey="pv" fill="#B7D8FD" />
                    </ComposedChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ display: "flex", minWidth: { xs: "90vw", sm: "initial" } }}
          >
            <Card sx={{ borderRadius: "20px" }}>
              <Box
                padding="40px 40px"
                height="100%"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      fontFamily: "Work Sans",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "27px",
                      letterSpacing: "-0.4px",
                    }}
                  >
                    One client generates more than 80% of income
                  </Box>
                </Stack>
                <Box
                  sx={{
                    color: "#192653",
                    fontFamily: "Work Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "21px",
                    letterSpacing: "-0.14px",
                  }}
                  padding="10px 0px"
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  To improve cash flow with heavy reliance on a single client,
                  it's crucial to diversify your client base and consider new
                  offerings to attract different customers.
                </Box>
                <Box width="100%" height={200}>
                  <ResponsiveContainer>
                    <BarChart data={endOfMonthChart} barCategoryGap={3}>
                      <Bar dataKey="e" shape={BarWithBorder(9.35)}>
                        {endOfMonthChart.map((entry, index) => (
                          <Cell
                            cursor="pointer"
                            fill={entry.color}
                            key={`cell-${index}`}
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid> */}
        {/* <Grid container padding="40px 0px">
          <Grid item xs={12} sm={12}>
            <Card
              sx={{
                marginLeft: { xs: 0, sm: 1 },
                marginRight: { xs: 0, sm: 3 },
                paddingY: 5,
                borderRadius: "20px",
              }}
            >
              <Box padding="40px 40px">
                <Stack
                  justifyContent={"space-between"}
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 4, sm: 0 }}
                >
                  <Box
                    sx={{
                      color: "#192653",
                      fontFamily: "Mulish",
                      fontSize: "32px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "40px",
                      letterSpacing: "-0.64px",
                    }}
                  >
                    Cash Flow Performance
                  </Box>
                  <ButtonGroup variant="outlined">
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        marginRight: "10px",
                        fontFamily: "Mulish",
                        fontSize: { xs: "14px", sm: "16px" },
                        lineHeight: "12px",
                        letterSpacing: "0.32px",
                        padding: { xs: "15px 20px", sm: "18px 24px" },
                        backgroundColor: "#1881FA",
                        fontWeight: "700",
                        ":hover": {
                          backgroundColor: "#1881FA",
                        },
                      }}
                      onClick={() => setSelectedPage("CashFlowForecast")}
                    >
                      Run Forecast
                    </Button>
                    <Button
                      sx={{
                        borderColor: "#DDDEE5",
                        bgcolor: selectedRange === "30days" ? "#E8F2FF" : "",
                        color: "#192653",
                        fontSize: { xs: "16px", sm: "24px" },
                        textTransform: "none",
                        borderRadius: "8px",
                      }}
                      onClick={() => setSelectedRange("30days")}
                    >
                      30 days
                    </Button>
                    <Button
                      sx={{
                        borderColor: "#DDDEE5",
                        bgcolor: selectedRange === "7days" ? "#E8F2FF" : "",
                        color: "#192653",
                        fontSize: { xs: "16px", sm: "24px" },
                        textTransform: "none",
                      }}
                      onClick={() => setSelectedRange("7days")}
                    >
                      7 days
                    </Button>
                  </ButtonGroup>
                </Stack>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 2, sm: 7 }}
                  marginTop={{ xs: 4, sm: 3 }}
                  alignItems={{ xs: "center", sm: "flex-start" }}
                  justifyContent={{ xs: "center", sm: "space-between" }}
                >
                  <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                    <Stack
                      direction="row"
                      spacing={4}
                      alignItems="center"
                      justifyContent="center"
                    >
                      {isLoadingAccountDetails ? (
                        <CircularProgress style={{ marginTop: "10px" }} />
                      ) : (
                        <>
                          <CircularProgressScore
                            value={
                              accountDetails.cashflowScore < 0
                                ? 0
                                : accountDetails.cashflowScore
                            }
                            size={120}
                            display={{ xs: "none", sm: "inline-flex" }}
                          />
                          <CircularProgressScore
                            value={
                              accountDetails.cashflowScore < 0
                                ? 0
                                : accountDetails.cashflowScore
                            }
                            size={120}
                            display={{ xs: "inline-flex", sm: "none" }}
                          />
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            spacing={{ xs: 2, sm: 0 }}
                            width={{ xs: "fit-content", sm: "initial" }}
                          >
                            <Chip
                              label={getTorScoreLabel(
                                accountDetails.cashflowScore < 0
                                  ? 0
                                  : accountDetails.cashflowScore
                              )}
                              size="medium"
                              sx={{
                                bgcolor: "#36C685",
                                color: "#ECFCF4",
                                leadingTrim: "both",
                                textEdge: "cap",
                                fontFamily: "Work Sans",
                                fontSize: { xs: "20px", sm: "18px" },
                                fontWeight: "600",
                                lineHeight: "24px",
                                letterSpacing: "-0.18px",
                                height: { xs: "40px", sm: "initial" },
                                width: { xs: "fit-content", sm: "initial" },
                                [`& .${chipClasses.label}`]: {
                                  overflow: "initial",
                                },
                              }}
                            />
                          </Stack>
                        </>
                      )}
                    </Stack>
                  </Box>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 2, sm: 3 }}
                  >
                    <Stack spacing={0.4}>
                      <Stack direction="row" spacing={0} alignItems="center">
                        <div
                          style={{
                            padding: "8px",
                            background: "#1881FA",
                            borderRadius: "2px",
                            marginRight: "5px",
                          }}
                        ></div>
                        <Box
                          sx={{
                            color: "#141E42",
                            fontFamily: "Work Sans",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "24px",
                            letterSpacing: "-0.16px",
                          }}
                        >
                          Income
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                          leadingTrim: "both",
                          textEdge: "cap",
                          fontFamily: "Work Sans",
                          fontSize: "48px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "56px",
                          letterSpacing: "-0.96px",
                        }}
                      >
                        {isLoading ? (
                          <CircularProgress style={{ marginTop: "10px" }} />
                        ) : (
                          <>
                            <Box
                              component={"span"}
                              sx={{
                                color: "#141E42",
                                opacity: 0.3,
                              }}
                            >
                              $
                            </Box>
                            <Box
                              component={"span"}
                              sx={{
                                color: "#192653",
                              }}
                            >
                              {income}
                            </Box>
                          </>
                        )}
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                          color: incomePercentage > 0 ? "#2DA56F" : "#AD2727",
                          fontFamily: "Work Sans",
                          fontSize: "20px",
                          fontStyle: "normal",
                          fontWeight: "300",
                          lineHeight: "27px",
                          letterSpacing: "-0.4px",
                        }}
                      >
                        {isLoading ? (
                          <div></div>
                        ) : (
                          <>
                            <Box>{incomePercentage > 0 ? "+" : "-"}</Box>
                            <Box>
                              {incomePercentage > 0
                                ? Math.floor(incomePercentage)
                                : Math.abs(Math.floor(incomePercentage))}
                              %
                            </Box>
                            {incomePercentage > 0 ? (
                              <TrendingUpOutlined
                                sx={{ borderColor: "#2DA56F" }}
                              />
                            ) : (
                              <TrendingDownOutlined
                                sx={{ borderColor: "#AD2727" }}
                              />
                            )}
                          </>
                        )}
                      </Stack>
                    </Stack>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        bgcolor: "#B7D8FD",
                        borderColor: "#B7D8FD",
                        width: 1.5,
                      }}
                    />
                    <Divider
                      sx={{
                        bgcolor: "#B7D8FD",
                        borderColor: "#B7D8FD",
                      }}
                    />
                    <Stack spacing={0.4}>
                      <Stack direction="row" spacing={0} alignItems="center">
                        <div
                          style={{
                            padding: "8px",
                            background: "#DDDEE5",
                            borderRadius: "2px",
                            marginRight: "5px",
                          }}
                        ></div>
                        <Box
                          sx={{
                            color: "#141E42",
                            fontFamily: "Work Sans",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "24px",
                            letterSpacing: "-0.16px",
                          }}
                        >
                          Expenses
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                          leadingTrim: "both",
                          textEdge: "cap",
                          fontFamily: "Work Sans",
                          fontSize: "48px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "56px",
                          letterSpacing: "-0.96px",
                        }}
                      >
                        {isLoading ? (
                          <CircularProgress style={{ marginTop: "10px" }} />
                        ) : (
                          <>
                            <Box
                              component={"span"}
                              sx={{
                                color: "#141E42",
                                opacity: 0.3,
                              }}
                            >
                              $
                            </Box>
                            <Box
                              component={"span"}
                              sx={{
                                color: "#192653",
                              }}
                            >
                              {expenses}
                            </Box>
                          </>
                        )}
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                          color: expensesPercentage > 0 ? "#2DA56F" : "#AD2727",
                          fontFamily: "Work Sans",
                          fontSize: "20px",
                          fontStyle: "normal",
                          fontWeight: "300",
                          lineHeight: "27px",
                          letterSpacing: "-0.4px",
                        }}
                      >
                        {isLoading ? (
                          <div></div>
                        ) : (
                          <>
                            <Box>{expensesPercentage > 0 ? "+" : "-"}</Box>
                            <Box>
                              {expensesPercentage > 0
                                ? Math.floor(expensesPercentage)
                                : Math.abs(Math.floor(expensesPercentage))}
                              %
                            </Box>
                            {expensesPercentage > 0 ? (
                              <TrendingUpOutlined
                                sx={{ borderColor: "#2DA56F" }}
                              />
                            ) : (
                              <TrendingDownOutlined
                                sx={{ borderColor: "#AD2727" }}
                              />
                            )}
                          </>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
              <Box
                width={{ xs: "100%", sm: "95%" }}
                height={{ xs: 250, sm: 350 }}
              >
                <ResponsiveContainer>
                  <LineChart data={chartData}>
                    <CartesianGrid vertical={false} strokeDasharray="3 3" />
                    <XAxis dataKey="day" />
                    <YAxis domain={[1000, "auto"]} />
                    <Tooltip />
                    <Legend />
                    <ReferenceLine y={UCL} stroke="red" strokeDasharray="3 3" />
                    <ReferenceLine y={LCL} stroke="red" strokeDasharray="3 3" />
                    <ReferenceArea
                      y1={LCL}
                      y2={UCL}
                      fill="#eaf6f1"
                      fillOpacity={0.9}
                    />
                    <Line
                      type="monotone"
                      dataKey="DailyNet"
                      stroke="#1261BC"
                      activeDot={{ r: 8 }}
                      dot={(props) => (
                        <CustomDot {...props} value={props.payload.DailyNet} />
                      )}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </Card>
          </Grid> */}
          {/* Commented out for MVP
          <Grid item xs={12} sm={4}>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                paddingX: { xs: 1, sm: 0 },
                marginTop: { xs: 2, sm: 0 },
              }}
            >
              <Box
                sx={{
                  color: "#192653",
                  fontFamily: "Mulish",
                  fontSize: "26px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "32px",
                  letterSpacing: "-0.52px",
                }}
              >
                Upcoming Bills
              </Box>
              <Select
                value="Bill Due"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="Bill Due">Bill Due</MenuItem>
              </Select>
            </Stack>
            <Stack marginTop="20px" spacing={1}>
              <Grid
                container
                alignItems="center"
                bgcolor="#E8F2FF"
                borderRadius="36px"
                padding="15px"
              >
                <Grid item xs={3}>
                  <Avatar src={XTwitter} sx={{ width: 64, height: 64 }} />
                </Grid>
                <Grid item xs={7}>
                  <Stack>
                    <Box
                      sx={{
                        fontFamily: "Work Sans",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "27px",
                        letterSpacing: "-0.4px",
                      }}
                    >
                      $129 + $12
                    </Box>
                    <Box
                      sx={{
                        color: "#192653",
                        fontFamily: "Work Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "21px",
                        letterSpacing: "-0.14px",
                      }}
                    >
                      Twitter Ad Subscription
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={2}>
                  <Avatar sx={{ bgcolor: "#FFFFFF", width: 64, height: 64 }}>
                    <ArrowForwardIos sx={{ color: "#1881FA" }} />
                  </Avatar>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                bgcolor="#E8F2FF"
                borderRadius="36px"
                padding="15px"
              >
                <Grid item xs={3}>
                  <Avatar src={LinkedIn} sx={{ width: 64, height: 64 }} />
                </Grid>
                <Grid item xs={7}>
                  <Stack>
                    <Box
                      sx={{
                        fontFamily: "Work Sans",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "27px",
                        letterSpacing: "-0.4px",
                      }}
                    >
                      $129
                    </Box>
                    <Box
                      sx={{
                        color: "#192653",
                        fontFamily: "Work Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "21px",
                        letterSpacing: "-0.14px",
                      }}
                    >
                      LinkedIn Ad Subscription
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={2}>
                  <Avatar sx={{ bgcolor: "#FFFFFF", width: 64, height: 64 }}>
                    <ArrowForwardIos sx={{ color: "#1881FA" }} />
                  </Avatar>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                bgcolor="#E8F2FF"
                borderRadius="36px"
                padding="15px"
              >
                <Grid item xs={3}>
                  <Avatar src={BankOfAmerica} sx={{ width: 64, height: 64 }} />
                </Grid>
                <Grid item xs={7}>
                  <Stack>
                    <Box
                      sx={{
                        fontFamily: "Work Sans",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "27px",
                        letterSpacing: "-0.4px",
                      }}
                    >
                      $129
                    </Box>
                    <Box
                      sx={{
                        color: "#192653",
                        fontFamily: "Work Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "21px",
                        letterSpacing: "-0.14px",
                      }}
                    >
                      Bank Service
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={2}>
                  <Avatar sx={{ bgcolor: "#FFFFFF", width: 64, height: 64 }}>
                    <ArrowForwardIos sx={{ color: "#1881FA" }} />
                  </Avatar>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                bgcolor="#E8F2FF"
                borderRadius="36px"
                padding="15px"
              >
                <Grid item xs={3}>
                  <Avatar src={BestBuy} sx={{ width: 64, height: 64 }} />
                </Grid>
                <Grid item xs={7}>
                  <Stack>
                    <Box
                      sx={{
                        fontFamily: "Work Sans",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "27px",
                        letterSpacing: "-0.4px",
                      }}
                    >
                      $129
                    </Box>
                    <Box
                      sx={{
                        color: "#192653",
                        fontFamily: "Work Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "21px",
                        letterSpacing: "-0.14px",
                      }}
                    >
                      Monthly Hardware Service
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={2}>
                  <Avatar sx={{ bgcolor: "#FFFFFF", width: 64, height: 64 }}>
                    <ArrowForwardIos sx={{ color: "#1881FA" }} />
                  </Avatar>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                bgcolor="#E8F2FF"
                borderRadius="36px"
                padding="15px"
              >
                <Grid item xs={3}>
                  <Avatar src={Microsoft} sx={{ width: 64, height: 64 }} />
                </Grid>
                <Grid item xs={7}>
                  <Stack>
                    <Box
                      sx={{
                        fontFamily: "Work Sans",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "27px",
                        letterSpacing: "-0.4px",
                      }}
                    >
                      $129
                    </Box>
                    <Box
                      sx={{
                        color: "#192653",
                        fontFamily: "Work Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "21px",
                        letterSpacing: "-0.14px",
                      }}
                    >
                      Office 365 Monthly
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={2}>
                  <Avatar sx={{ bgcolor: "#FFFFFF", width: 64, height: 64 }}>
                    <ArrowForwardIos sx={{ color: "#1881FA" }} />
                  </Avatar>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                bgcolor="#E8F2FF"
                borderRadius="36px"
                padding="15px"
              >
                <Grid item xs={3}>
                  <Avatar src={Bill} sx={{ width: 64, height: 64 }} />
                </Grid>
                <Grid item xs={7}>
                  <Stack>
                    <Box
                      sx={{
                        fontFamily: "Work Sans",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "27px",
                        letterSpacing: "-0.4px",
                      }}
                    >
                      $1029
                    </Box>
                    <Box
                      sx={{
                        color: "#192653",
                        fontFamily: "Work Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "21px",
                        letterSpacing: "-0.14px",
                      }}
                    >
                      Local Rent
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={2}>
                  <Avatar sx={{ bgcolor: "#FFFFFF", width: 64, height: 64 }}>
                    <ArrowForwardIos sx={{ color: "#1881FA" }} />
                  </Avatar>
                </Grid>
              </Grid>
            </Stack>
          </Grid> */}
        {/* </Grid> */}
        {/* TEMPORARILY REMOVED FOR MVP
         <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Box
              width="100%"
              height={350}
              sx={{
                borderRadius: "24px",
                border: "2px dashed #B7D8FD",
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                sx={{
                  height: "64px",
                  width: "64px",
                  boxShadow:
                    " 0px 6px 20px 0px rgba(22, 31, 66, 0.04), 0px 2px 5px 0px rgba(0, 0, 0, 0.04)",
                }}
              >
                <Add sx={{ color: "#1881FA" }} />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              width="100%"
              height={350}
              sx={{
                borderRadius: "24px",
                border: "2px dashed #B7D8FD",
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                sx={{
                  height: "64px",
                  width: "64px",
                  boxShadow:
                    " 0px 6px 20px 0px rgba(22, 31, 66, 0.04), 0px 2px 5px 0px rgba(0, 0, 0, 0.04)",
                }}
              >
                <Add sx={{ color: "#1881FA" }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid> */}
      {/* </Box> */}
    </>
  );
};
