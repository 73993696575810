import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import api from './api';
import authSlice from './slices/authSlice';
import alertSlice from './slices/alertSlice';
import alertAccountsSlice from './slices/alertAccountsSlice';

const rootReducer = combineReducers({
  auth: authSlice,
  alert: alertSlice,
  alertAccounts: alertAccountsSlice,
  [api.reducerPath]: api.reducer,
})


export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(api.middleware),
});
export const useAppDispatch: () => TAppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector;

export type TRootState = ReturnType<typeof rootReducer>;
export type TAppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);