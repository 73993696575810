import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';
import { TRootState } from "../../store";
import { hideAlert } from '../../store/slices/alertSlice';

const GlobalAlert: React.FC = () => {
  const dispatch = useDispatch();
  const { open, message } = useSelector((state: TRootState) => state.alert);

  const handleClose = () => {
    dispatch(hideAlert());
  };

  if (!open) return null;

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">
        {message}
        </Alert>
    </Snackbar>
  );
};

export default GlobalAlert;