import { createSlice } from '@reduxjs/toolkit';

interface AlertAccountsState {
  open: boolean;
}

const initialState: AlertAccountsState = {
  open: false,
};

const alertAccountsSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showAlertAccounts: (state,) => {
      state.open = true;
    },
    hideAlertAccounts: (state) => {
      state.open = false;
    },
  },
});

export const { showAlertAccounts, hideAlertAccounts } = alertAccountsSlice.actions;
export default alertAccountsSlice.reducer;