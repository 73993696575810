import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from "../../store";
import { Box, Button,Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { hideAlertAccounts } from "../../store/slices/alertAccountsSlice";

  
const GlobalAlert: React.FC = () => {
  const dispatch = useDispatch();
  const { open } = useSelector((state: TRootState) => state.alertAccounts);

  if (!open) return null;

  return (
    <Modal
    open={open}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        width: "400px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div></div>
        <CloseIcon
          onClick={() => dispatch(hideAlertAccounts())}
          style={{ cursor: "pointer" }}
        />
      </div>
      <h3 style={{ marginBottom: "10px" }}>
        Seems like you did not connect your business accounts
      </h3>
      <span
        style={{ marginBottom: "20px", display: "block", color: "#666" }}
      >
        In order to provide 360 view of your finances, accurate Tor Score
        and Cashflow Forecast, you would need to connect at least one
        (preferably all) business accounts you have. The more accounts you
        connect, calculations and metrics will be more accurate.
      </span>
      <Button
        variant="contained"
        color="primary"
        sx={{
          fontFamily: "Mulish",
          textTransform: "none",
          fontSize: { xs: "16px", sm: "16px" },
          lineHeight: "12px",
          letterSpacing: "0.32px",
          padding: { xs: "18px 24px", sm: "18px 24px" },
          width: "100%",
          marginBottom: "10px",
          backgroundColor: "#1881FA",
          fontWeight: "700",
          ":hover": {
            backgroundColor: "#1881FA",
          },
        }}
        onClick={() => {
          window.top
            ? (window.top.location.href =
                "https://magnolia-liafi.uat.narmitech.com/settings/linked_accounts")
            : (window.location.href =
                "https://magnolia-liafi.uat.narmitech.com/settings/linked_accounts");
        }}
      >
        Connect your account
      </Button>
      <Button
        variant="outlined"
        color="primary"
        sx={{
          fontFamily: "Mulish",
          textTransform: "none",
          fontSize: { xs: "16px", sm: "16px" },
          lineHeight: "12px",
          letterSpacing: "0.32px",
          padding: { xs: "18px 24px", sm: "18px 24px" },
          width: "100%",
          fontWeight: "700",
          borderColor: "#1881FA",
          color: "#1881FA",
          ":hover": {
            borderColor: "#1881FA",
            backgroundColor: "rgba(24, 129, 250, 0.1)",
          },
        }}
        onClick={() => {
          window.top
            ? (window.top.location.href =
                "https://magnolia-liafi.uat.narmitech.com")
            : (window.location.href =
                "https://magnolia-liafi.uat.narmitech.com");
        }}
      >
        I don`t need my Tor Score
      </Button>
    </Box>
  </Modal>
  );
};

export default GlobalAlert;