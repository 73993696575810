import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Tooltip,
  chipClasses,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { CircularProgressScore } from "../common/CircularProgressScore";
import TorScoreGraph from "../../assets/svg/turtle.svg";
import { useSelector } from "react-redux";
import { TRootState } from "../../store";
import {
  InitialState,
} from "../../store/slices/authSlice";
import useAccountDetails from "../../hooks/useAccountDetails";
import useYTD from "../../hooks/useYTD";
import { Tooltip as MuiTooltip } from "@mui/material";
import { IPageProps } from "../../models/common";
import { getTorScoreLabel } from "../../utils/calculations";
import { formatUserName } from "../../utils/common";

export const LandingPage: React.FC<IPageProps> = ({ setSelectedPage }) => {
  const interestRate = useSelector(
    (state: TRootState) => (state.auth as InitialState).interestRate
  );
  const user = useSelector(
    (state: TRootState) => (state.auth as InitialState).user
  );
  const isLoadingAccountDetails = useSelector(
    (state: TRootState) => (state.auth as InitialState).isLoadingAccountDetails
  );
  const isLoadingYTD = useSelector(
    (state: TRootState) => (state.auth as InitialState).isLoadingYTD
  );
  const accountDetails = useAccountDetails();
  const interestEarned = useYTD();

  return (
    <Grid
      container
      direction="column"
      alignItems={{ xs: "left", sm: "center" }}
      justifyContent={{ xs: "flex-start", sm: "center" }}
      minHeight="100vh"
    >
      <Box
        component={"span"}
        sx={{
          position: "relative",
          top: { xs: "50px", sm: "0px" },
          textAlign: { xs: "left", sm: "center" },
          fontFamily: "Work Sans",
          letterSpacing: "-0.96px",
          fontSize: { xs: "35px", sm: "28px" },
          fontWeight: "300",
          color: "#192653",
          paddingLeft: { xs: "20px", sm: "none" },
          width: { xs: "fit-content", sm: "100%" },
        }}
      >
        Welcome back {user ? formatUserName(user) : ""}
      </Box>
      <Box
        sx={{
          background: `url(${TorScoreGraph})`,
          backgroundPosition: { xs: "-150px", sm: "-110px" },
          backgroundSize: "100",
          backgroundRepeat: "no-repeat",
          paddingTop: "30px",
          width: { xs: "100%", sm: "1100px" },
        }}
      >
        <Box
          sx={{
            position: "relative",
            top: { xs: "120px", sm: "0px" },
            margin: "auto",
            width: { xs: "90%", sm: "750px" },
            height: { xs: "initial", sm: "250px" },
            borderRadius: "32px",
            background: "none",
          }}
        >
          <Box
            sx={{
              position: "relative",
              top: { xs: "-70px", sm: "20px" },
              width: { xs: "100%", sm: "700px" },
              margin: "auto",
              height: {
                xs: "fit-content",
                sm: "fit-content",
                lg: "fit-content",
              },
              borderRadius: "20px",
              boxShadow: "0px 4px 16px 1px rgba(22, 31, 66, 0.02)",
              background: "rgba(255, 255, 255, 1)",
              padding: { xs: "48px 35px", sm: "40px 60px" },
            }}
          >
            <Box
              sx={{
                textAlign: { xs: "center", sm: "left" },
                fontFamily: "Work Sans",
                fontSize: { xs: "28px", sm: "24px" },
                lineHeight: "27px",
                letterSpacing: "-0.48px",
                padding: { xs: "0px", sm: "initial" },
              }}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            >
              Tor Score
              <MuiTooltip
                title={
                  <span style={{fontSize: '14px'}}>
                    Tor Score is a cutting-edge tool designed to revolutionize
                    the way you monitor your business finances. Want to learn
                    more?{" "}
                    <button
                      onClick={() => {
                        window.top
                          ? (window.top.location.href =
                              "https://liafi.co/tor-score/")
                          : (window.location.href =
                              "https://liafi.co/tor-score/");
                      }}
                      style={{
                        background: "none",
                        border: "none",
                        padding: "0",
                        margin: "0",
                        color: "inherit",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Click here
                    </button>
                  </span>
                }
                arrow
              >
                <InfoOutlined sx={{ margin: "0px 3px", color: "#B8BCCA" }} />
              </MuiTooltip>
            </Box>
            <Stack
              sx={{
                marginTop: "40px",
                display: "flex",
                width: { xs: "100%", sm: "100%" },
              }}
              spacing={{ xs: 4, sm: 0 }}
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                sx={{
                  width: { xs: "100%", sm: "50%" },
                  minWidth: { xs: "100%", sm: "50%" },
                }}
              >
                {isLoadingAccountDetails ? (
                  <CircularProgress style={{ marginTop: "10px" }} />
                ) : (
                  <>
                    <Stack
                      direction="row"
                      spacing={4}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CircularProgressScore
                        value={
                          accountDetails.cashflowScore < 0
                            ? 0
                            : accountDetails.cashflowScore
                        }
                        size={120}
                        display={{ xs: "none", sm: "inline-flex" }}
                      />
                      <CircularProgressScore
                        value={
                          accountDetails.cashflowScore < 0
                            ? 0
                            : accountDetails.cashflowScore
                        }
                        size={100}
                        display={{ xs: "inline-flex", sm: "none" }}
                      />
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        spacing={{ xs: 2, sm: 0 }}
                        width={{ xs: "fit-content", sm: "initial" }}
                      >
                        <Chip
                          label={getTorScoreLabel(
                            accountDetails.cashflowScore < 0
                              ? 0
                              : accountDetails.cashflowScore
                          )}
                          size="medium"
                          sx={{
                            bgcolor: "#36C685",
                            color: "#ECFCF4",
                            leadingTrim: "both",
                            textEdge: "cap",
                            fontFamily: "Work Sans",
                            fontSize: { xs: "16px", sm: "16px" },
                            padding: { xs: "", sm: "2px 5px" },
                            fontWeight: "600",
                            lineHeight: "24px",
                            letterSpacing: "-0.18px",
                            height: { xs: "40px", sm: "initial" },
                            width: { xs: "fit-content", sm: "initial" },
                            [`& .${chipClasses.label}`]: {
                              overflow: "initial",
                            },
                            marginLeft: { xs: "0px", sm: "-22px !important" },
                          }}
                        />
                      </Stack>
                    </Stack>
                  </>
                )}
              </Box>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  display: {
                    xs: "none",
                    sm: "initial",
                    borderColor: "#B7D8FD",
                    marginLeft: "0px !important",
                  },
                }}
              />
              <Divider
                sx={{
                  display: { sm: "none", borderColor: "#B7D8FD" },
                  width: "100%",
                }}
              />
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: { xs: "100%", sm: "50%" },
                  paddingLeft: { xs: "0px", sm: "40px" },
                }}
              >
                <Box
                  component={"span"}
                  sx={{
                    color: "#192653",
                    fontFamily: "Work Sans",
                    fontSize: { xs: "20px", sm: "20px" },
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "27px",
                    letterSpacing: "-0.4px",
                    textAlign: "left",
                    padding: { xs: "0px", sm: "initial" },
                    width: "max-content !important",
                  }}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  Cash on Hand
                  <MuiTooltip
                    title={
                      <span style={{fontSize: '14px'}}>
                        Aggregated amount from all accounts linked in LiaFi. There could be a 24-hour delay based on your recent transaction history.
                      </span>
                    }
                    arrow
                  >
                    <InfoOutlined
                      sx={{ margin: "0px 3px", color: "#B8BCCA" }}
                    />
                  </MuiTooltip>
                </Box>
                <Box
                  component={"span"}
                  sx={{
                    color: "#192653",
                    leadingTrim: "both",
                    textEdge: "cap",
                    fontFamily: "Work Sans",
                    fontSize: { xs: "40px", sm: "48px" },
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "56px",
                    letterSpacing: "-0.96px",
                    width: "max-content !important",
                  }}
                >
                  {isLoadingAccountDetails ? (
                    <CircularProgress style={{ marginTop: "10px" }} />
                  ) : (
                    <>
                      <Box
                        component={"span"}
                        sx={{
                          color: "#B8BCCA",
                          marginRight: "4px",
                        }}
                      >
                        $
                      </Box>
                      {accountDetails.cashOnHand}
                    </>
                  )}
                </Box>
              </Stack>
            </Stack>
            <Stack
              sx={{ marginTop: "20px", display: "flex", width: "100%" }}
              spacing={{ xs: 4, sm: 0 }}
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "start", sm: "center" },
                  width: { xs: "100%", sm: "50%" },
                }}
              >
                <Box
                  component={"span"}
                  sx={{
                    color: "#192653",
                    fontFamily: "Work Sans",
                    fontSize: { xs: "20px", sm: "20px" },
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "27px",
                    letterSpacing: "-0.4px",
                    textAlign: "left",
                    padding: { xs: "0px", sm: "initial" },
                    width: "max-content !important",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  LiaFi Account APY
                  <Tooltip
                    title={
                      <span style={{fontSize: '14px'}}>
                        The percentage rate reflecting the total amount of interest paid on an account based on the interest rate and the frequency of compounding for a one-year period.
                      </span>
                    }
                    arrow
                  >
                      <InfoOutlined
                        sx={{ margin: "0px 3px", color: "#B8BCCA" }}
                      />
                  </Tooltip>
                </Box>
                <Box
                  component={"span"}
                  sx={{
                    color: "#192653",
                    leadingTrim: "both",
                    textEdge: "cap",
                    fontFamily: "Work Sans",
                    fontSize: { xs: "40px", sm: "48px" },
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "56px",
                    letterSpacing: "-0.96px",
                    width: "max-content !important",
                  }}
                >
                  {interestRate ? interestRate.toFixed(2) : "0"}
                  <Box
                    component={"span"}
                    sx={{
                      color: "#B8BCCA",
                    }}
                  >
                    %
                  </Box>
                </Box>
              </Stack>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  display: {
                    xs: "none",
                    sm: "initial",
                    borderColor: "#B7D8FD",
                    marginLeft: "0px !important",
                  },
                }}
              />
              <Divider
                sx={{ display: { sm: "none", borderColor: "#B7D8FD", width: "100%" } }}
              />
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: { xs: "100%", sm: "50%" },
                  paddingLeft: { xs: "0px", sm: "40px" },
                }}
              >
                <Box
                  component={"span"}
                  sx={{
                    color: "#192653",
                    fontFamily: "Work Sans",
                    fontSize: { xs: "18px", sm: "16.8px" },
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "27px",
                    letterSpacing: "-0.4px",
                    textAlign: "left",
                    padding: { xs: "0px", sm: "initial" },
                    width: { xs: "fit-content", sm: "max-content" },
                  }}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <span style={{textWrap: 'wrap'}}> Total LiaFi Interest Earned YTD</span>
                  <MuiTooltip
                    title={
                      <span style={{fontSize: '14px'}}>
                       Total LiaFi interest earned over the past year on cash deposits up to today’s date.
                       </span>
                      }
                    arrow
                  >
                    <InfoOutlined
                      sx={{ margin: "0px 3px", color: "#B8BCCA" }}
                    />
                  </MuiTooltip>
                </Box>
                <Box
                  component={"span"}
                  sx={{
                    color: "#192653",
                    leadingTrim: "both",
                    textEdge: "cap",
                    fontFamily: "Work Sans",
                    fontSize: { xs: "40px", sm: "48px" },
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "56px",
                    letterSpacing: "-0.96px",
                    width: "fit-content !important",
                  }}
                >
                  {isLoadingYTD ? (
                    <CircularProgress style={{ marginTop: "10px" }} />
                  ) : (
                    <>
                      <Box
                        component={"span"}
                        sx={{
                          color: "#B8BCCA",
                          marginRight: "4px",
                        }}
                      >
                        $
                      </Box>
                      {interestEarned.YTD}
                    </>
                  )}
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "block" },
          position: "relative",
          top: { xs: "130px", sm: "initial" },
          margin: { xs: "50px auto 0px auto", sm: "200px auto 0px auto" },
          width: { xs: "95%", sm: "700px" },
          backgroundColor: "rgba(255, 255, 255, 0.75)",
          borderRadius: "20px",
        }}
      >
        {/* Commented out for MVP
         <Box
          sx={{
            borderRadius: "25%",
            textAlign: "center",
            fontSize: { xs: "18px", sm: "18px" },
            padding: { xs: "22px 30px", sm: "24px 32px" },
          }}
        >
          End of the month is crowded with expenses, improve your cash flow
        </Box> */}
      </Box>
      <Box textAlign="center" sx={{ marginTop: "10px", marginBottom: "10px" }}>
        <Button
          variant="contained"
          sx={{
            position: "relative",
            top: { xs: "80px", sm: "initial" },
            fontFamily: "Mulish",
            textTransform: "none",
            fontSize: { xs: "20px", sm: "16px" },
            lineHeight: "12px",
            letterSpacing: "0.32px",
            padding: { xs: "18px 24px", sm: "18px 24px" },
            marginBottom: { xs: "50px", sm: "0px" },
            width: "fit-content",
            backgroundColor: "#1881FA",
            fontWeight: "700",
            ":hover": {
              backgroundColor: "#1881FA",
            },
          }}
          onClick={() => setSelectedPage("Dashboard")}
        >
          Get Detailed Insights
        </Button>
      </Box>
    </Grid>
  );
};
